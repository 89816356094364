/* eslint-disable vue/no-side-effects-in-computed-properties */
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout},
  data() {
    return {
      title: "",
      logo : "",
      appname : "",
      ismaintance : "",
      baseColor : "",
      secondColor : "",
      favicon : "",
      logoFIle : null,
      faviconFIle : null,
      redisHost : "",
      sentrydsn : "",
      stripe_sk : "",
      stripe_wk : "",
      smtp_host : "",
      smtp_password : "",
      smtp_port : "",
      smtp_sender : "",
      smtp_user : "",
      dip_url : "",
      id : ""
    }
  },
  computed:{
    loading(){
        return this.$store.getters['setting/loading'];
    },
    setting(){
        return this.$store.getters['setting/setting'];
    },
  },
  created(){
  },
  mounted(){
      this.$store.dispatch("setting/detail")
      .then((sett)=>{
            this.logo = sett.logo
            this.appname = sett.appname
            this.ismaintance = sett.ismaintance,
            this.baseColor = sett.baseColor,
            this.secondColor = sett.secondColor
            this.favicon = sett.favicon
            this.stripe_sk = sett.stripe_sk,
            this.stripe_wk = sett.stripe_wk,
            this.smtp_host = sett.smtp_host,
            this.smtp_password = sett.smtp_password,
            this.smtp_port = sett.smtp_port,
            this.smtp_sender = sett.smtp_sender,
            this.smtp_user = sett.smtp_user,
            this.dip_url = sett.dip_url,
            this.id = sett._id
      })
  },
  methods : {
    uploadFile(){
      document.getElementById("logoUpload").click()
    },
    choosFileIcon(e){
        let files = document.getElementById("logoUpload").files;
        const file = e.target.files[0];
        this.logo = URL.createObjectURL(file);
        this.logoFIle = files[0]
    },
    FaviconClick(){
      document.getElementById("faviconUpload").click()
    },
    choosFavicon(e){
        let files = document.getElementById("faviconUpload").files;
        const file = e.target.files[0];
        this.favicon = URL.createObjectURL(file);
        this.faviconFIle = files[0]
    },
    save(){
        var formData = new FormData()
        if(this.logoFIle != null){
            formData.append("logo",this.logoFIle)
        }
        if(this.faviconFIle != null){
            formData.append("favicon",this.faviconFIle)
        }
        if(this.id != ""){
            formData.append("id",this.id)
        }
        formData.append("appname",this.appname)
        formData.append("ismaintance",this.ismaintance)
        formData.append("baseColor",this.baseColor)
        formData.append("secondColor",this.secondColor)
        formData.append("favicon",this.favicon)
        formData.append("stripe_sk",this.stripe_sk)
        formData.append("stripe_wk",this.stripe_wk)
        formData.append("smtp_host",this.smtp_host)
        formData.append("smtp_password",this.smtp_password)
        formData.append("smtp_port",this.smtp_port)
        formData.append("smtp_sender",this.smtp_sender)
        formData.append("smtp_user",this.smtp_user)
        formData.append("dip_url",this.dip_url)
        this.$store.dispatch('setting/create',formData)
        .then((success)=>{
            this.$toast.open({
                message: 'Saved',
                type: 'success',
            });
        })
        .catch((error)=>{
            this.$toast.open({
                message: error,
                type: 'error',
            });
        })
    }
  }

};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Dashboard </h4>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Tanggal : 05-Apr-2021 | Pukul : 03:35:34 , Selamat Malam</li>
            </ol>
          </div>
        </div>
            <div class="row" >
              <div class="col-md-6">
                <b-overlay :show="loading" rounded="sm">
                    <div class="card"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="" class="control-label">App Name : </label>
                                <input type="text" class="form-control" v-model="appname">
                            </div>
                        </div>
                    </div>
                </b-overlay>
                <b-overlay :show="loading" rounded="sm">
                    <div class="card"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="" class="control-label">App Logo : </label>
                                <input @change="choosFileIcon" id="logoUpload" type="file" hidden>
                                <div>
                                    <b-img crossorigin="anonymous" :src="logo" fluid-grow alt="Responsive image"></b-img>
                                </div>
                            </div>
                            <button class="btn btn-sm btn-warning" @click="uploadFile()">Change Logo</button>
                        </div>
                    </div>
                </b-overlay>
                <b-overlay :show="loading" rounded="sm">
                    <div class="card"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="" class="control-label">App Favicon : </label>
                                <input @change="choosFavicon" id="faviconUpload" type="file" hidden>
                                <div>
                                    <b-img crossorigin="anonymous" :src="favicon" fluid alt="Responsive image"></b-img>
                                </div>
                            </div>
                            <button class="btn btn-sm btn-warning" @click="FaviconClick()">Change Favicon</button>
                        </div>
                    </div>
                </b-overlay>
                <b-overlay :show="loading" rounded="sm">
                    <div class="card"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="" class="control-label">Base Color : </label>
                                <input type="color" class="form-control" v-model="baseColor">
                            </div>
                        </div>
                    </div>
                </b-overlay>
                <b-overlay :show="loading" rounded="sm">
                    <div class="card"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="" class="control-label">Second Color : </label>
                                <input type="color" class="form-control" v-model="secondColor">
                            </div>
                        </div>
                    </div>
                </b-overlay>
                <b-overlay :show="loading" rounded="sm">
                    <div class="card"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="" class="control-label">Maintance : </label>
                                <b-form-checkbox v-model="ismaintance" name="check-button" switch>
                                    {{ismaintance ? 'site under maintance' : 'site active'}}
                                </b-form-checkbox>
                            </div>
                        </div>
                    </div>
                </b-overlay>
              </div>
              <div class="col-md-6">
                <b-overlay :show="loading" rounded="sm">
                    <div class="card"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="" class="control-label">Stripe Sekret_Key : </label>
                                <input type="text" class="form-control" v-model="stripe_sk">
                            </div>
                            <div class="form-group">
                                <label for="" class="control-label">Stripe Webhook_key : </label>
                                <input type="text" class="form-control" v-model="stripe_wk">
                            </div>
                            <div class="form-group">
                                <label for="" class="control-label">SMTP HOST : </label>
                                <input type="text" class="form-control" v-model="smtp_host">
                            </div>
                            <div class="form-group">
                                <label for="" class="control-label">SMTP PASSWORD : </label>
                                <input type="text" class="form-control" v-model="smtp_password">
                            </div>
                            <div class="form-group">
                                <label for="" class="control-label">SMTP PORT : </label>
                                <input type="text" class="form-control" v-model="smtp_port">
                            </div>
                            <div class="form-group">
                                <label for="" class="control-label">SMTP SENDER : </label>
                                <input type="text" class="form-control" v-model="smtp_sender">
                            </div>
                            <div class="form-group">
                                <label for="" class="control-label">SMTP USER : </label>
                                <input type="text" class="form-control" v-model="smtp_user">
                            </div>
                            <div class="form-group">
                                <label for="" class="control-label">Frontend Domain : </label>
                                <input type="text" class="form-control" v-model="dip_url">
                            </div>
                        </div>
                    </div>
                </b-overlay>
               
                <button class="btn btn-sm btn-primary btn-block" @click="save()">Save</button>
              </div>
            </div>
      </div>
    </div>
  </Layout>
</template>
